let user = JSON.parse(process.env.REACT_APP_USER_TYPES);

export const UserType = {
  super: user?.super,
  admin: user?.admin,
  management: user?.management,
  user: user?.user,
  student: user?.student,
  changed: user?.changed,
  parent: user?.parent,
  applicant: user?.applicant,
};

let role = JSON.parse(process.env.REACT_APP_USER_ROLE);

export const UserRole = {
  super: role?.super,
  admin: role?.admin,
  management: role?.management,
  user: role?.user,
  student: role?.student,
  visitor: role?.visitor,
  parent: role?.parent,
  applicant: role?.applicant,
};

export const SubModulesName = {
  // setup: {
  FinancialSession: "Financial Session",
  Class: "Class And Section",
  Section: "Class And Section",
  Stream: "Stream",
  Documents: "Documents",
  Subject: "Subject",
  ClassSection: "Class And Section",
  Group: "Group",
  Configuration: "Config",
  Houses: "Houses",
  OtherSetting: "Other Setting",
  SchoolInformation: "School Information",
  Bank: "Bank And Branches",
  Branch: "Bank And Branches",
  ClassGroupSubject: "Class Section Subject Mapping",
  Route: "Transport",
  TransportCircle: "Transport",
  Stops: "Transport",
  TransportImport: "Transport",
  HostelType: "Hostel And Room",
  HostelRoom: "Hostel And Room",
  RouteStop: "Transport",
  HostelBlock: "Hostel And Room",
  LeaveRemarks: "Leave Remark",
  TransportStop: "Transport",
  HolidayCalendar: "Holiday Calendar",
  ManageDesignation: "Manage Designation",
  ManageDepartment: "Manage Department",
  AdmissionNumberFormat: "Admission Number Format",
  TemplateLibrary: "Template Library",
  VendorManagement: "Vendor Management",

  // user: {
  UserInfo: "User Info",
  UserLog: "User Log",
  User: "User",
  UserRight: "User Permission",
  // },
  // sms: {
  SmsTemplate: "Sms Template",
  StudentGeneralSMS: "Student General Sms",
  StudentTransportSMS: "Student Transport Sms",
  StudentSMS: "Student Sms",
  StudentFeeSMS: "Student Fee Sms",
  ResultSMS: "Result Sms",
  AttendanceSMS: "Attendance Sms",
  LibrarySMS: "Student Library Sms",
  EmployeeGeneralSMS: "Employee General Sms",
  EmployeeLeaveSMS: "Employee Leave Sms",
  EmployeeLibrarySMS: "Employee Library Sms",
  EmployeeSalarySMS: "Employee Salary Sms",
  EmployeeTaxSMS: "Employee Income Tax Sms",
  EmployeeIncomeSMS: "Employee Increment Sms",
  EmployeeArrearNBonus: "Employee Arrear & Bonus Sms",
  ImportExcel: "Excel Import Sms",
  EnquirySMS: "Enquiry Sms",
  RegistrationSMS: "Registration Sms",
  GroupImport: "Group Import",
  GroupSMS: "Group Sms",
  GeneralGroupSMS: "General Group Sms",
  LogSMS: "Sms Log",
  // },
  // attendance: {
  StudentAttendance: "Student Attendance And Leave",
  StudentSmartCard: "SmartCard Student Attendance",
  StudentReport: "Student Attendance Report",
  // },
  // webCms: {
  Category: "Category",
  AwardAndAchievement: "Award And Achievement",
  BulletinBoard: "Bulletin Board",
  Circular: "Circular",
  Events: "Event",
  Page: "Media Press",
  News: "News",
  PhotoGallery: "Gallery",
  Slider: "Slider",
  Publication: "Publication",
  Thought: "Thought Of The Day",
  VideoGallery: "Video Gallery",
  // },
  // academicCms: {
  HomeReport: "Homework Report",
  AnnualCalendar: "Annual Calender",
  AnnualPlan: "Annual Plan",
  Assignment: "Assignment",
  DateSheet: "Datesheet",
  Homework: "Homework",
  QuestionBank: "Question Bank",
  Syllabus: "Syllabus",
  Timetable: "Time Table",
  Tutorials: "Tutorials",
  // },
  // webManager: {
  MediaAssetPage: "Media Asset",
  MenuManager: "Menu Manager",
  PageSection: "Page Section",
  WebPage: "Web Page",
  // },

  // || Start: Notification
  StudentGeneralNotifications: "Student General Notifications",
  StudentFeeNotifications: "Student Fee Notifications",
  StudentTransportNotifications: "Student Transport Notifications",
  AttendanceNotifications: "Attendance Notifications",
  NotificationsLog: "Notifications Log",
  // || End: Notification

  // || Start: Payroll
  PayrollSettings: "Payroll Settings",
  PayrollEmployeeInfo: "Payroll Employee Info",
  PayrollFWM: "Payroll Field Wise Modification",
  SalaryGeneration: "Payroll Salary Generation",
  PayrollHourlyPay: "Payroll Hourly Pay",
  PayrollSalaryChange: "Payroll Salary Change",
  PayrollProjectedSalary: "Payroll Projected Salary",
  PayrollSalaryArrear: "Payroll Salary Arrear",
  PayrollPaymentInfo: "Payroll Payment Info",
  PayrollReports: "Payroll Reports",
  PayrollEmployeeDetailsExcelImport: "Payroll Employee Details Excel Import",
  // || End: Payroll

  //whatsapp
  WhatsappMessage: "Whatsapp Message",
  WhatsappLog: "Whatsapp Log",
  WhatsappTemplate: "Whatsapp Template",
  WhatsappCofiguration: "Whatsapp Cofiguration",

  //staff attendance start-------------------------------------------

  //--- geofence  start------
  staffGeofenceLocationSettings: "Geofence Location",
  geofenceLocationAllotment: "Geofence Location Allotment",
  //--- geofence  end------
  // staffSettings: "Financial Session",
  staffFinancialSettings: "Financial Session",
  staffEntryStepper: "Employee Details",

  staffAttendanceLog: "Attendance Log",
  //---  shifts start------
  staffShiftSetting: "Shift Setting",
  staffDepartmentShiftAssignment: "Department Shift Assignment",
  staffEmpShiftAllotment: "Employee Shift Allotment",
  //---  shifts end ------

  //--- leave settings start------
  staffLeaveType: "Leave Type",
  staffLeaveName: "Leave Name",
  staffLeaveStructure: "Leave Structure",
  //--- leave settings end------
  //red mark start ----------------------
  staffRedMarkConfiguration: "Redmark Configuration",
  staffRedMarkAllotment: "Redmark Allotment",
  staffRedMarkReports: "Redmark Report",
  //red mark end ----------------------

  staffAssignHoliday: "Assign Holiday",
  staffAttendance: "Attendance",
  staffLeaves: "Advance Leave",
  staffRegularizeRequest: "Attendance Regularization",
  staffWFHRequest: "Work From Home Request",
  staffCarryForwardLeave: "Carry Forward Leave",
  staffMachineIdAllotment: "Machine Id Allotment",
  // staffReports: "Reports",
  staffLeaveSummaryReports: "Leave Summary Report",
  staffMonthlyAttendanceSummaryReports: "Monthly Attendance Summary Report",
  staffConsolidatedReports: "Employee Attendance Consolidated Report",
  //staff attendance ends ---------------------------------------------

  // || start: Visitor Management
  VisitorManagementSettings: "Visitor Management Settings",
  VisitorManagementScheduledMeeting: "Scheduled Meeting",
  VisitorManagementBulkVisit: "Bulk Visit",
  VisitorManagementTimeTrack: "Time Tracking",
  VisitorManagementUnScheduledMeeting: "Unschedule Meeting",
  VisitorManagementVisitorsEnquiry: "Visitors Enquiry",

  //recruitment
  RecruitmentJobPost: "Recruitment Job Post",
  ApplicantReports: "Applicant Reports",
  CreateJobPost: "Create Job Post",
};

export const AllPages = {
  super: {
    p1: "layouts.allPages.super.p1",
    p2: "layouts.allPages.super.p2",
    p3: "layouts.allPages.super.p3",
    p4: "layouts.allPages.super.p4",
    p5: "layouts.allPages.super.p5",
    p6: "layouts.allPages.super.p6",
    p7: "layouts.allPages.super.p7",
    p8: "layouts.allPages.super.p8",
    p9: "layouts.allPages.super.p9",
    p10: {
      s0: "layouts.allPages.super.p10.s0",
      s1: "layouts.allPages.super.p10.s1",
      s2: "layouts.allPages.super.p10.s2",
    },
    p11: {
      s0: "layouts.allPages.super.p11.s0",
      s1: "layouts.allPages.super.p11.s1",
      s2: "layouts.allPages.super.p11.s2",
    },
    p12: "layouts.allPages.super.p12",
    p13: "layouts.allPages.super.p13",
    p14: "layouts.allPages.super.p14",
  },
  admin: {
    p1: "layouts.allPages.admin.p1",
    p2: {
      s0: "layouts.allPages.admin.p2.s0",
      s1: "layouts.allPages.admin.p2.s1",
      s2: "layouts.allPages.admin.p2.s2",
      s4: "layouts.allPages.admin.p2.s4",
      s5: "layouts.allPages.admin.p2.s5",
      s6: "layouts.allPages.admin.p2.s6",
      s7: "layouts.allPages.admin.p2.s7",
      s8: "layouts.allPages.admin.p2.s8",
      s9: "layouts.allPages.admin.p2.s9",
      s10: "layouts.allPages.admin.p2.s10",
      s11: "layouts.allPages.admin.p2.s11",
      s12: "layouts.allPages.admin.p2.s12",
    },
    p3: "layouts.allPages.admin.p3",
    p4: {
      s0: "layouts.allPages.admin.p4.s0",
      s1: "layouts.allPages.admin.p4.s1",
      s2: "layouts.allPages.admin.p4.s2",
      s3: "layouts.allPages.admin.p4.s3",
      s4: "layouts.allPages.admin.p4.s4",
      s5: "layouts.allPages.admin.p4.s5",
      s6: "layouts.allPages.admin.p4.s6",
      s7: "layouts.allPages.admin.p4.s7",
      s8: "layouts.allPages.admin.p4.s8",
      s9: "layouts.allPages.admin.p4.s9",
      s10: "layouts.allPages.admin.p4.s10",
      s11: "layouts.allPages.admin.p4.s11",
      s12: "layouts.allPages.admin.p4.s12",
    },
    p5: {
      s0: "layouts.allPages.admin.p5.s0",
      s1: "layouts.allPages.admin.p5.s1",
      s2: "layouts.allPages.admin.p5.s2",
      s3: "layouts.allPages.admin.p5.s3",
      s4: "layouts.allPages.admin.p5.s4",
      s5: "layouts.allPages.admin.p5.s5",
      s6: "layouts.allPages.admin.p5.s6",
      s7: "layouts.allPages.admin.p5.s7",
      s8: "layouts.allPages.admin.p5.s8",
      s9: "layouts.allPages.admin.p5.s9",
      s10: "layouts.allPages.admin.p5.s10",
      s11: "layouts.allPages.admin.p5.s11",
      s12: "layouts.allPages.admin.p5.s12",
      s13: "layouts.allPages.admin.p5.s13",
      s14: "layouts.allPages.admin.p5.s14",
      s15: "layouts.allPages.admin.p5.s15",
      s16: "layouts.allPages.admin.p5.s16",
      s17: "layouts.allPages.admin.p5.s17",
      s18: "layouts.allPages.admin.p5.s18",
      s19: "layouts.allPages.admin.p5.s19",
      s20: "layouts.allPages.admin.p5.s20",
      s21: "layouts.allPages.admin.p5.s21",
    },
    p6: {
      s0: "layouts.allPages.admin.p6.s0",
      s1: "layouts.allPages.admin.p6.s1",
      s2: "layouts.allPages.admin.p6.s2",
      s3: "layouts.allPages.admin.p6.s3",
      s4: "layouts.allPages.admin.p6.s4",
      s5: "layouts.allPages.admin.p6.s5",
      s6: "layouts.allPages.admin.p6.s6",
      s7: "layouts.allPages.admin.p6.s7",
      s8: "layouts.allPages.admin.p6.s8",
      s9: "layouts.allPages.admin.p6.s9",
    },
    p7: {
      s0: "layouts.allPages.admin.p7.s0",
      s1: "layouts.allPages.admin.p7.s1",
      s2: "layouts.allPages.admin.p7.s2",
      s3: "layouts.allPages.admin.p7.s3",
      s4: "layouts.allPages.admin.p7.s4",
    },
    p8: {
      s0: "layouts.allPages.admin.p8.s0",
      s1: "layouts.allPages.admin.p8.s1",
      s2: "layouts.allPages.admin.p8.s2",
      s3: "layouts.allPages.admin.p8.s3",
      s4: "layouts.allPages.admin.p8.s4",
      s5: "layouts.allPages.admin.p8.s5",
      s6: "layouts.allPages.admin.p8.s6",
    },
    p9: {
      s0: "layouts.allPages.admin.p9.s0",
      s1: "layouts.allPages.admin.p9.s1",
    },
    p10: {
      s0: "layouts.allPages.admin.p10.s0",
      s1: "layouts.allPages.admin.p10.s1",
      s2: "layouts.allPages.admin.p10.s2",
      s3: "layouts.allPages.admin.p10.s3",
      s4: "layouts.allPages.admin.p10.s4",
    },
    p11: {
      s0: "layouts.allPages.admin.p11.s0",
      s1: "layouts.allPages.admin.p11.s1",
      s2: "layouts.allPages.admin.p11.s2",
      s3: "layouts.allPages.admin.p11.s3",
      s4: "layouts.allPages.admin.p11.s4",
      s5: "layouts.allPages.admin.p11.s5",
      s6: "layouts.allPages.admin.p11.s6",
      s7: "layouts.allPages.admin.p11.s7",
      s8: "layouts.allPages.admin.p11.s8",
      s9: "layouts.allPages.admin.p11.s9",
      s10: "layouts.allPages.admin.p11.s10",
      s11: "layouts.allPages.admin.p11.s11",
      s12: "layouts.allPages.admin.p11.s12",
      s13: "layouts.allPages.admin.p11.s13",
      s14: "layouts.allPages.admin.p11.s14",
    },
    p12: {
      s0: "layouts.allPages.admin.p12.s0",
      s1: "layouts.allPages.admin.p12.s1",
      s2: "layouts.allPages.admin.p12.s2",
      s3: "layouts.allPages.admin.p12.s3",
      s4: "layouts.allPages.admin.p12.s4",
      s5: "layouts.allPages.admin.p12.s5",
      s6: "layouts.allPages.admin.p12.s6",
      s7: "layouts.allPages.admin.p12.s7",
    },
    p13: "layouts.allPages.admin.p13",
    p14: "layouts.allPages.admin.p14",
    p15: {
      s0: "layouts.allPages.admin.p15.s0",
      s1: "layouts.allPages.admin.p15.s1",
      s2: "layouts.allPages.admin.p15.s2",
      s3: "layouts.allPages.admin.p15.s3",
      s4: "layouts.allPages.admin.p15.s4",
      s5: "layouts.allPages.admin.p15.s5",
      s6: "layouts.allPages.admin.p15.s6",
      s7: "layouts.allPages.admin.p15.s7",
      s8: "layouts.allPages.admin.p15.s8",
    },
    p16: "layouts.allPages.admin.p16",
    p17: {
      s0: "layouts.allPages.admin.p17.s0",
      s1: "layouts.allPages.admin.p17.s1",
      s2: "layouts.allPages.admin.p17.s2",
      s3: "layouts.allPages.admin.p17.s3",
      s4: "layouts.allPages.admin.p17.s4",
      s5: "layouts.allPages.admin.p17.s5",
      s6: "layouts.allPages.admin.p17.s6",
    },
    p18: {
      s0: "layouts.allPages.admin.p18.s0",
      s1: "layouts.allPages.admin.p18.s1",
      s2: "layouts.allPages.admin.p18.s2",
      s3: "layouts.allPages.admin.p18.s3",
      s4: "layouts.allPages.admin.p18.s4",
      s5: "layouts.allPages.admin.p18.s5",
      s6: "layouts.allPages.admin.p18.s6",
    },
    p19: {
      s0: "layouts.allPages.admin.p19.s0",
      s1: "layouts.allPages.admin.p19.s1",
      s2: "layouts.allPages.admin.p19.s2",
      s3: "layouts.allPages.admin.p19.s3",
      s4: "layouts.allPages.admin.p19.s4",
      s5: "layouts.allPages.admin.p19.s5",
    },
    p20: "layouts.allPages.admin.p20",
    p21: {
      s0: "layouts.allPages.admin.p21.s0",
      s1: "layouts.allPages.admin.p21.s1",
      s2: "layouts.allPages.admin.p21.s2",
      s3: "layouts.allPages.admin.p21.s3",
      s4: "layouts.allPages.admin.p21.s4",
      s5: "layouts.allPages.admin.p21.s5",
      s6: "layouts.allPages.admin.p21.s6",
      s7: "layouts.allPages.admin.p21.s7",
      s8: "layouts.allPages.admin.p21.s8",
      s9: "layouts.allPages.admin.p21.s9",
      s10: "layouts.allPages.admin.p21.s10",
      s11: "layouts.allPages.admin.p21.s11",
    },
    p22: "layouts.allPages.admin.p22",
    p23: "layouts.allPages.admin.p23",
  },
  manager: {
    p1: "layouts.allPages.manager.p1",
    p2: "layouts.allPages.manager.p2",
  },
  applicant: {
    p1: "layouts.allPages.applicant.p1",
    p2: "layouts.allPages.applicant.p2",
    p3: "layouts.allPages.applicant.p3",
  },
};

export const AWSTagsForDocs = {
  TUTORIALS: {
    img: "academic-cms-tutorials-img",
    doc: "academic-cms-tutorials-doc",
  },
  HOMEWORK: {
    img: "academic-cms-homework-img",
    doc: "academic-cms-homework-doc",
  },
  DATESHEET: {
    img: "academic-cms-datesheet-img",
    doc: "academic-cms-datesheet-doc",
  },
  ASSIGNMENT: {
    img: "academic-cms-assignment-img",
    doc: "academic-cms-assignment-doc",
  },
  TIME_TABLE: {
    img: "academic-cms-timetable-img",
    doc: "academic-cms-timetable-doc",
  },
  SYLLABUS: {
    img: "academic-cms-syllabus-img",
    doc: "academic-cms-syllabus-doc",
  },
  QUESTION_BANK: {
    img: "academic-cms-questionbank-img",
    doc: "academic-cms-questionbank-doc",
  },
  ANNUAL_PLAN: {
    img: "academic-cms-anualplan-img",
    doc: "academic-cms-anualplan-doc",
  },
  ANNUAL_CALENDER: {
    img: "academic-cms-anualcalendar-img",
    doc: "academic-cms-anualcalendar-doc",
  },
  NEWS: {
    img: "web-cms-news-img",
    doc: "web-cms-news-doc",
  },
  EVENT: {
    img: "web-cms-event-img",
    doc: "web-cms-event-doc",
  },
  CIRCULAR: {
    img: "web-cms-circular-img",
    doc: "web-cms-circular-doc",
  },
  GALLERY: {
    img: "web-cms-gallery-img",
  },
  SLIDER: {
    img: "web-cms-slider-img",
  },
  PUBLICATION: {
    img: "web-cms-publication-img",
    doc: "web-cms-publication-doc",
  },
  MEDIA_PRESS: {
    img: "web-cms-media_press-img",
    doc: "web-cms-media_press-doc",
  },
  BULLETIN_BOARD: {
    img: "web-cms-bulletin_board-img",
    doc: "web-cms-bulletin_board-doc",
  },
  AWARD_AND_ACHIEVEMENT: {
    img: "web-cms-award_and_achievement-img",
    doc: "web-cms-award_and_achievement-doc",
  },
  STUDENT_INFO_IMG: {
    img: "student-info-img",
    doc: "student-info-doc",
  },
  STUDENT_INFO_IMG_FATHER: {
    img: "student-info-img-father",
    doc: "student-info-doc-father",
  },
  STUDENT_INFO_IMG_MOTHER: {
    img: "student-info-img-mother",
    doc: "student-info-doc-mother",
  },
  STUDENT_INFO_IMG_GUARDIAN: {
    img: "student-info-img-gaurdian",
    doc: "student-info-doc-gaurdian",
  },
  SCHOOL_MEDIA: {
    img: "school-media",
  },
  EMPLOYEE_MACHINE_ID: {
    img: "employee-attendance-machineId",
  },
  EMPLOYEE_DAILY_ATTENDANCE: {
    img: "employee-daily-attendance-import",
  },
  STUDENT_INFO_EXCEL: {
    img: "student-record-excel",
  },
  BOARD_ROLL_NUMBER_ALLOTMENT: {
    img: "board-roll-number-allotment",
  },
  MARK_ENTRY: {
    img: "mark-entry",
  },
  WEBSITE_TEMPLATE: {
    img: "web-manager-template-media",
    doc: "web-manager-template-media",
  },
  WEBSITE_WEB: {
    img: "web-manager-page-media",
    doc: "web-manager-page-media",
  },
  REPORT_TEMPLATE: {
    img: "report-template-section",
    doc: "report-template-section",
  },
  SCHOOL_EXCECL_IMPORT: {
    img: "school-excel",
  },
  GROUP_SMS_EXCEL: {
    img: "group-sms-excel",
  },
  TRANSPORT_ROUTE_IMPORT: {
    img: "student-transport-excel",
    doc: "student-transport-excel",
  },
  CONCESSION_IMPORT: {
    img: "student-concession-excel",
    doc: "student-concession-excel",
  },
  HOSTEL_IMPORT: {
    img: "student-hostel-excel",
    doc: "student-hostel-excel",
  },
  EMPLOYEE_IMAGE_PAYROLL: {
    img: "employee-info-img",
  },
  SUBJECT_IMPORT: {
    img: "subject-import",
    doc: "subject-import",
  },
  FEE_COLLECTION_IMPORT: {
    img: "fee-collection-excel",
    doc: "fee-collection-excel",
  },
  FEE_CONSESSION_IMPORT: {
    img: "fee-consession-excel",
    doc: "fee-consession-excel",
  },
  STUDENT_REGISTRATION_DOCUMENT: {
    img: "student-registration-documents",
    doc: "student-registration-documents",
  },
  STUDENT_REGISTRATION_INSTRUCTIONS: {
    img: "registration-instructions",
    doc: "registration-instructions",
  },
  EMPLOYEE_IMAGE: {
    img: "employee-info-img",
  },
  STAFF_ATTENDANCE_LEAVEOPENING: {
    img: "employee-leave-opening",
    // doc: "employee-leave-opening",
  },
  JOB_POST: {
    img: "recruitment-jobpost",
    doc: "recruitment-jobpost",
  },
  VISITOR_MANAGEMENT: {
    img: "visitor-attachment",
  },
  VISITOR_IMPORT: {
    img: "visitor-import",
    doc: "visitor-import",
  },
  FEE_GROUP_EXCEL: {
    img: "fee-group",
    doc: "fee-group",
  },
  FEE_COMPONENT_EXCEL: {
    img: "fee-component",
    doc: "fee-component",
  },
  LESSON_PLANNING_IMPORT: {
    doc: "lesson-import",
    img: "lesson-import",
  },
  LESSON_PLANNING: {
    doc: "lesson-attachment",
    img: "lesson-attachment",
  },
  JOB_APPLY: {
    img: "web-cms-recruitment-job-apply",
    doc: "web-cms-recruitment-job-apply",
  },
  PUBLIC_JOB_APPLY: {
    img: "web-cms-recruitment-job-apply",
    doc: "web-cms-recruitment-job-apply",
  },
  FWM_IMPORT: {
    img: "fieldwise-import",
    doc: "fieldwise-import",
  },
  CLASS_MIGRATION: {
    doc: "class-section",
    img: "class-section",
  },
  STUDENT_MIGRATION: {
    doc: "student-migration",
    img: "student-migration",
  },
  PARENTS_MIGRATION: {
    doc: "parent-migration",
    img: "parent-migration",
  },
  "CIRCLES-EXCEL": {
    doc: "circles-excel",
    img: "circles-excel",
  },
  "CIRCLES-STOP-EXCEL": {
    doc: "circles-stop-excel",
    img: "circles-stop-excel",
  },
  "ROUTES-EXCEL": {
    doc: "routes-excel",
    img: "routes-excel",
  },
  "ROUTES-STOP-EXCEL": {
    doc: "routes-stop-excel",
    img: "routes-stop-excel",
  },
  FEE_INSTALLMENT_MIGRATION: {
    doc: "fee-installment-migration",
    img: "fee-installment-migration",
  },
  FEE_COMPONENT_MIGRATION: {
    doc: "fee-component-migration",
    img: "fee-component-migration",
  },
  FEE_COLLECTION_MIGRATION: {
    doc: "fee-collection-migration",
    img: "fee-collection-migration",
  },
  FEE_STRUCTURE_MIGRATION: {
    doc: "fee-structure-excel",
    img: "fee-structure-excel",
  },
  FEE_INSTALLMENT_IMPORT: {
    doc: "fee-installment-excel",
    img: "fee-installment-excel",
  },
  UPLOAD_SLC: {
    doc: "web-cms-slc",
    img: "web-cms-slc",
  },
  USERS_EXCEL_IMPORT: {
    doc: "user-migration",
    img: "user-migration",
  },
  SUPER_TEMPLATE_MANAGER: {
    doc: "template-manager",
    img: "template-manager",
  },
  TEMPLATE_MANAGER: {
    doc: "school-template-manager",
    img: "school-template-manager",
  },
  STUDENT_SECTION_IMPORT: {
    doc: "section-import",
    img: "section-import",
  },
  EMPLOYEE_PAYROLL_EXCEL: {
    doc: "employee-payroll-details-excel",
    img: "employee-payroll-details-excel",
  },
  WHATSAPP_GROUP_USER_EXCEL: {
    doc: "whatsapp-group-user-excel",
    img: "whatsapp-group-user-excel",
  },
};
